/*Regular*/
@font-face {
    font-family: 'Graphik';
    src: url('../fonts/graphik/Graphik-Regular-Web.eot');
    src: url('../fonts/graphik/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/graphik/Graphik-Regular-Web.woff2') format('woff2'),
    url('../fonts/graphik/Graphik-Regular-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}
  
/*Bold*/
@font-face {
    font-family: 'Graphik Bold';
    src: url('../fonts/graphik/Graphik-Bold-Web.eot');
    src: url('../fonts/graphik/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/graphik/Graphik-Bold-Web.woff2') format('woff2'),
    url('../fonts/graphik/Graphik-Bold-Web.woff') format('woff');
  }
  
  .bold {
    font-family: 'Graphik Bold';
    font-weight:  700;
    font-style:   normal;
    font-stretch: normal;
  }
  
  /*Semi Bold*/
  @font-face {
    font-family: 'Graphik Semi Bold';
    src: url('../fonts/graphik/Graphik-Semibold-Web.eot');
    src: url('../fonts/graphik/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/graphik/Graphik-Semibold-Web.woff2') format('woff2'),
    url('../fonts/graphik/Graphik-Semibold-Web.woff') format('woff');
  }
  
  .semiBold {
    font-family: 'Graphik Semi Bold';
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
  }
  
  /*Medium*/
  @font-face {
    font-family: 'Graphik Medium';
    src: url('../fonts/graphik/Graphik-Medium-Web.eot');
    src: url('../fonts/graphik/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/graphik/Graphik-Medium-Web.woff2') format('woff2'),
    url('../fonts/graphik/Graphik-Medium-Web.woff') format('woff');
  }
  
  .medium {
    font-family: 'Graphik Medium';
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  }
  
  /*Light*/
  @font-face {
    font-family: 'Graphik Light';
    src: url('../fonts/graphik/Graphik-Light-Web.eot');
    src: url('../fonts/graphik/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/graphik/Graphik-Light-Web.woff2') format('woff2'),
    url('../fonts/graphik/Graphik-Light-Web.woff') format('woff');
  }
  
  .light {
    font-family: 'Graphik Light';
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
  }
  /*All Commercial fonts contain kerning, but it needs to be turned on in the CSS for it to work.
  To turn on kerning, include the following attributes in your css.*/
  body {
    -moz-font-feature-settings: "liga", "kern";
    -moz-font-feature-settings:"liga=1, kern=1";
    -ms-font-feature-settings:"liga", "kern";
    -o-font-feature-settings:"liga", "kern";
    -webkit-font-feature-settings:"liga", "kern";
    font-feature-settings: "liga", "kern";
  }
  