.timeout-modal-popup 
  .modal-header
    display: flex 
    flex-direction: row 
    justify-content: flex-start 
    align-items: center 
    padding: 2.5vh 1.88vw 2.5vh 1.46vw 
  .modal-title 
    flex: 69.09 
    margin: -5px 0 0 11px 
    font-family: Graphik 
    font-size: 24px 
    font-weight: 600 
    font-stretch: normal 
    font-style: normal 
    line-height: 0.67 
    letter-spacing: normal 
    color: #4e4e87 
  .modal-body-content 
    font-size: 16px 
    line-height: 1.5 
    color: #333333 
    margin: 0 0 2.68vh 0 
    font-family: Graphik 
    font-weight: normal 
    font-stretch: normal 
    font-style: normal 
    letter-spacing: normal 
    min-height: 18.5vh 
  .popmsg-styles  
    margin-bottom: 20px
  .time 
    font-family: Graphik 
    font-size: 32px 
    font-weight: 600 
    font-stretch: normal 
    font-style: normal 
    line-height: normal 
    letter-spacing: normal 
    color: #5b63e9 
    display: block 
    text-align: center 
  .dialog-margin 
    margin: 0 30px 20px 0 
  .time-End
   font-size:16px
   font-family: Graphik

  .time-Extend 
    font-size: 16px 
    font-family: Graphik 
    margin-left: 20px 
  .time-logout 
    font-size: 16px 
    font-family: Graphik 
    width: 95px 