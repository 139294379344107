.ui-overflow-hidden {
    overflow: hidden;
  }
  
  .ui-dialog-mask {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
  }
  
  .ui-widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .85;
  }
  
  .reject-dialog {
    width: 33rem;
    position: fixed;
    padding: 1.4rem;
    border: 1px solid #D5D5D5;
    background: #fcfcfc;
  }
  
  .dialog-header-icon {
    border: 2px solid #fcfcfc;
    margin-top:-60px;
    display:inline-block;
    background:#ffffff;
    vertical-align: middle;
  }
  
  .dialog-header-icon.warning {
    color:#CC1E32;
  }
  
  .dialog-header-icon.question {
    color:#AB8C30;
  }
  
  .dialog-header-icon.success {
    color: #00a5a6;
  }
  
  .dialog-header-text {
    color: #333;
    font-size: 18px;
    text-align: center;
  }
  
  .rounded-circle {
    border-radius: 50%!important;
}