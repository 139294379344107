.form {
    padding-top: 1.25rem;
}

.form .line {
    margin: 0 0.7rem;
    line-height: 2.25rem;
}

.form_left {
    padding-right: 3rem;
}

.form_right {
    padding-left: 3rem;
}

.form_sub_left {
    padding-right: 2rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding: 0;
}

.form-group {
    margin-bottom: 1.5rem;
}

.custom-form-group {
    padding: 10px;
    min-height: 105px;
}

.custom-form-group label {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #333;
    line-height: 1.25rem;
}

.custom-form-group span.customSubLabel {
    display: block;
    font-style: italic;
    font-weight: 100;
}

.custom-form-group label .normalWeight {
    font-weight: normal;
}

.custom-form-group span.customUnderSubLabel {
    display: block;
    font-weight: bold;
}

.form-group label {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #333;
    line-height: 1.25rem;
}

label.rc-container.ng-star-inserted {
    display: inline-block !important;
}

.form-group span.desc {
    display: block;
    margin-top: -.5rem;
    font-style: italic;
    margin-bottom: 1rem;
}

.form-group span.subdesc {
    display: block;
    margin-top: -.5rem;
    font-style: italic;
    margin-bottom: 1rem;
    font-weight: 100;
    padding-top: 15px;
}

.form-group span.oneline {
    margin-top: -.5rem;
    font-style: italic;
    margin-bottom: 1rem;
    font-weight: 100;
}

.tip_container {
    position: relative;
}

.tip {
    position: absolute;
    top: -.35rem;
    right: 2rem;
    background: #fff;
    color: #666;
    font-size: .85rem;
    padding: 0 .5rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.6rem + 2px);
    padding: .375rem .75rem;
    background: #f6f5fa;
    color: #666666;
    border-radius: .25rem
}

.form-control:focus {
    border: #b6b7ce 1px solid;
    box-shadow: 0 0 5px #ccc;
    background: #fff;
    color: #666;
}

.form-control.alert {
    border: #f3abd1 1px solid;
}

.material-icons.tooltip {
    color: #379fe9;
    font-size: 1.25rem;
    cursor: pointer;
    margin-left: .3rem;
}

.btn {
    font-weight: bold;
    border: 0;
    opacity: .9;
    padding: 1rem 3rem;
    border-radius: 2rem;
    cursor: pointer;
}

.btn:hover,
.btn:focus {
    opacity: 1;
    outline: none;
}

.btn.disabled {
    opacity: .2;
}

.btn.btn-primary {
    color: #fff;
    background: -webkit-linear-gradient(left, #764ba2, #3C73D2);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #764ba2, #3C73D2);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #764ba2, #3C73D2);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #764ba2, #3C73D2);
    /* standard */
}

.btn.secondary {
    color: #4e4e87;
    background: rgba(78, 78, 135, 0.1);
}

.btn .material-icons {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
}

.form_validation_message {
    background: #FFE8E6;
    color: #633752;
    padding: .75rem;
    border-radius: .25rem;
    font-size: 0.9rem;
}

.form_validation_message1 {
    background: rgb(250, 225, 186, .5);
    color: #633752;
    padding: .75rem;
    border-radius: .25rem;
    font-size: 0.9rem;
}

.form_validation_message .material-icons {
    font-size: 1.25rem;
    color: #CC1E32;
    margin-right: .5rem;
}

.waringMsg {
    color: #6E4E03 !important;
}

.form_validation_message1 .material-icons {
    font-size: 1.25rem;
    color: #6E4E03;
    margin-right: .5rem;
}

.form_validation_message .message {
    flex-grow: 1;
}

select.sort {
    border: 0;
    border-bottom: #4E4E87 1px solid;
    background: transparent
}

input.header-search::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #014972;
    opacity: 1;
    /* Firefox */
}

input.header-search:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #014972;
}

input.header-search::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #014972;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-prepend {
    margin-right: -1px;
    display: flex;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    background: #d6d6de;
    color: #666;
    font-weight: bold;
    border-radius: .25rem;
}

.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}


/*Custom checkbox and radio button*/


/* The container */

.rc-container {
    display: inline !important;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: normal;
    color: #333;
    margin-right: 3rem;
    padding-top: .3rem;
}

.multiple-dropdown {
    font-weight: normal !important;
    padding-left: 25px;
    font-size: 0.93rem;
    margin-right: 0px;
}


/* Hide the browser's default checkbox */

.rc-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: #4e4e87 2px solid;
}


/*set round corner for radio button*/

.checkmark.r {
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.rc-container:hover input~.checkmark {
    background-color: #fff;
}

.mat-input-element:disabled {
    background: white;
    padding: 0;
    color: #666666!important;
    border: 0
}

textarea:disabled {
    border: 0;
    background: white;
}

input[type="text"]:disabled {
    border: 0;
    background: white;
    padding: 0;
}

select:disabled {
    border: 0;
    /* width: auto; */
    padding: .375rem .75rem;
    /* background: white!important; */
}


/* When the checkbox is disabled, add a blue background */

.rc-container input[type="checkbox"]:disabled~.checkmark {
    opacity: 0.5
}

.rc-container input[type="radio"]:disabled~.checkmark {
    opacity: 0.5
}


/* When the checkbox is checked, add a blue background */

.rc-container input:checked~.checkmark {
    background-color: #4e4e87;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.rc-container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.rc-container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 .15rem .15rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/*Customized select arrow*/

select {
    background: rgba(78, 78, 135, 0.1) url("../../assets/images/baseline-expand_more.svg") no-repeat right .75rem center !important;
    background-size: 1.5rem !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select:focus {
    background: #ffffff url("../../assets/images/baseline-expand_more.svg") no-repeat right .75rem center !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff!important;
    box-shadow: 0 0 0 3px #ccc;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: #2E8548!important
}

.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #ff6369!important
}

.mat-slide-toggle.mat-disabled {
    opacity: 100!important
}