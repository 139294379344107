/* You can add global styles to this file, and also import other
@import "../node_modules/ng-zorro-antd/style/index.min.css"
@import "../node_modules/ng-zorro-antd/button/style/index.min.css"
@import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css"
@import "./assets/css/site.css"
@import "./assets/css/common.css"
@import "./assets/css/table.css"
@import "./assets/css/form.css"
@import "./assets/css/icons.css"
@import "./assets/css/fonts.css"
@import "./assets/css/dialog.css"
@import "./assets/css/progress.css"
@import "./assets/sass/common.sass"


@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css"
